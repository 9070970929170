<script setup>
import { ref } from 'vue'
import axios from 'axios';

const contact_name = ref("");
const contact_email = ref("");
const contact_message = ref("");
const visibleContactForm = ref(false)
const newsletter_email = ref("");
const contacting = ref(false)
const contactOK = ref(false)
const contactError = ref(false)
const newsing = ref(false)
const newsOK = ref(false)
const newsError = ref(false)

const submitContact = () =>{
  contacting.value = true;
  axios.defaults.headers.common["Content-type"] = "application/json ";
  axios.post('https://dev-api.volunto.eu/users/contactUser',
    {
    "email" : contact_email.value,
    "name" : contact_name.value,
    "middle_name": "",
    "bornDate": "",
    "movil" : "",
    "message" : contact_message.value
    })
    .then(response => {
      contacting.value = false;
      if (response.status == 200){
        contactOK.value = true;
      }
    }).catch(error => {
      console.log(error)
      contacting.value = false;
      contactError.value = true;
    })
} 
const submitNewsletter = () =>{
  newsing.value = true;
  axios.post('https://dev-api.volunto.eu/users/contactEmail',
    {
    "email" : newsletter_email.value,
    })
    .then(response => {
      newsing.value = false;
      if (response.status == 200){
        newsOK.value = true;
      }
    }).catch(error => {
      console.log(error)
      newsing.value = false;
      newsError.value = true;
    })
} 
</script>

<template>
  <div class="container-bkg" id="home">
     <div class="container">
      <header class="pt-4">
        <nav class="navbar navbar-expand-md navbar-dark fixed-top">
          <div class="container-fluid">
            <router-link to="/" href="/" class="navbar-brand mb-10">
              <img src="../assets/img/logo.png" alt="Logo Volunto">
            </router-link>
            <div>
              <a href="https://app.volun.to/auth/login" class="d-sm-none">
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#fff" class="bi bi-person-fill" viewBox="0 0 16 16">
                  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"/>
                </svg>
              </a>
              <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
              </button>
            </div>
            <div class="collapse navbar-collapse sm-justify-content-between" id="navbarSupportedContent" ref="navbarSupportedContent">
              <ul class="navbar-nav me-auto mb-1 mb-sm-0">
                <li class="nav-item">
                  <a class="nav-link" href="#home">Inicio</a>
                </li>
                <li class="nav-item ms-4">
                  <a class="nav-link" href="#how">Cómo funciona</a>
                </li>
                <li class="nav-item ms-4">
                  <a class="nav-link" href="#prices">Precios</a>
                </li>
                <li class="nav-item ms-4">
                  <a class="nav-link"  @click="visibleContactForm=true">Contacta</a>
                </li>
              </ul>
              <div class="d-block d-sm-flex ml-auto align-items-center">
                  <a href="https://app.volun.to/auth/login" class="text-primary text-decoration-none">Acceder</a>
                <div class="separator-top-menu"></div>
                <a href="https://app.volun.to/auth/register" class="btn volunto-btn-primary px-6 py-6 ml-5">Regístrate</a>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <section class="container-fluid">
        <div class="row">
          <div class="col col-md-7 col-12 justify-center section1">
            <h1 class="font-pt text-white">Y si mañana <span>murieras...</span><br />
            ¿Lo tendrías todo bajo control?</h1>
            <p class="text-grey">Guarda tu legado digital de forma segura y envíaselo a tus herederos seleccionados el día que fallezcas.</p>
            <a href="https://app.volun.to/auth/register" class="btn volunto-btn-primary mt-3 px-5">Crea tu <strong>Legado Digital</strong></a>
          </div>
          <div class="col col-md-5 col-12">
            <img class="image_banner1" alt="Y si mañana mueres..." src="../assets/img/image_banner1.png" />
          </div>
        </div>
      </section>
    </div>
  </div>
  <div class="container" id="how">
    <section class="container-fluid section2">
      <div class="row">
        <div class="col col-md-9 col-12 justify-center">
          <span class="subtitle">Cómo funciona</span>
          <h3 class="font-pt">Guarda tu Legado <span>de forma segura,</span> y envíaselo de forma automática <span>a tus herederos</span> cuando fallezcas</h3>
        </div>
        <div class="col col-md-3 col-12">
          <img alt="Guarda tu legado digital" src="../assets/img/image_banner2.png" />
        </div>
      </div>
    </section>
    <section class="container-fluid ">
      <div class="row">
        <div class="section21">
          <div class="content">
            <div class="content_block">
              <div class="icon"><img src="../assets/img/img_section2_1.svg" alt="Guarda tu información de forma segura"></div>
              <span>Guarda tu información de forma segura.</span>
              <p>Gracias al uso de la última tecnología en encriptación y seguridad de datos, todos los datos quedan protegidos de posibles robos o accesos no autorizados.<br /><br />
              Nuestro equipo de expertos en seguridad informática y tecnología trabaja constantemente para mantener nuestra plataforma actualizada y ofrecer el mejor servicio posible.
              </p>
            </div>
          </div>
          <div class="content">
            <div class="content_block">
              <div class="icon"><img src="../assets/img/img_section2_2.svg" alt="Elige tus herederos y asígnales sus Legados"></div>
              <span>Elige tus herederos y asígnales sus Legados.</span>
              <p>Invita a los herederos que quieras y asígnales que Legados tendrán cada cuando fallezcas.<br /><br />
              Elige entre las diferentes formas de identificar que has fallecido y cuando se cumpla el requisito,  el sistema automáticamente permitirá que los herederos asignados abran sus Legados.
              </p>
            </div>
          </div>
          <div class="content">
            <div class="content_block">
              <div class="icon"><img src="../assets/img/img_section2_3.svg" alt="Cuando fallezcas tus herederos tendrán acceso"></div>
              <span>Cuando fallezcas tus herederos tendrán acceso.</span>
              <p>Una vez se haya solicitado el proceso de apertura de tu Legado por fallecimiento, se abrirá un periodo de seguridad mínimo de tiempo, donde se activarán varios mecanismos de seguridad para evitar accesos no deseados a la información.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="container-fluid section3">
      <div class="row">
        <div class="col col-md-6 col-12">
          <img alt="Guarda tu legado digital" src="../assets/img/image_banner3.png" />
        </div>
        <div class="col col-md-6 col-12 justify-center">
          <span class="subtitle">Qué puedes almacenar</span>
          <h3 class="font-pt"><span>Si te importa</span> y puedes pensar en ello, puedes <span>guardarlo</span></h3>
          <p>Crea tus propios Legados Digitales a tu antojo. Y si no sabes por donde empezar utiliza alguna de nuestra plantillas preconfiguradas, para asegúrate que todo está bien guardado.</p>
          <div class="content-list">
            <ul>
              <li>Cuentas bancarias</li>
              <li>Tarjetas de crédito</li>
              <li>Criptomonedas</li>
              <li>NFTs</li>
              <li>Servicios digitales</li>
              <li>Acesso a e-mails</li>
            </ul>
            <ul>
              <li>Escrituras</li>
              <li>Seguros</li>
              <li>Propiedades</li>
              <li>Últimas voluntades</li>
              <li>El mapa de un tesoro</li>
              <li>etc....</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <div class="container container-section4">
      <img class="image-flot" alt="Despídete de los tuyos como se merecen" src="../assets/img/image_banner4.png" />
      <section class="container-fluid section4">
        <div class="row">
          <div class="col col-md-6 col-12 justify-center">
            <span class="subtitle">Envía un mensaje personalizado</span>
            <h3 class="font-pt">Despídete de los tuyos como <span>se merecen</span></h3>
            <p>Sorprende a familiares y amigos con mensajes o videos, después de tu fallecimiento, para dar consejos, saludos o lo que creas oportuno.<br /><br />
            Puedes elegir el mejor momento para que se envíen. En una fecha concreta en un cumpleaños o un aniversario. Solo graba el mensaje, elige destinatario y listo.<br /><br />
            Imagina un abuelo con su nieto recién nacido al que le quiere mandar un mensaje para cuando cumpla 18 años.
            </p>
            <a href="https://app.volun.to/auth/register" class="d-none d-sm-inline btn volunto-btn-primary mt-3 px-5">Regístrate y pruébalo</a>
          </div>
          <div class="col col-md-6 col-12">
            <img class="image_video" alt="Guarda tu legado digital" src="../assets/img/image2_banner4.png" />
            <a href="https://app.volun.to/auth/register" class="d-flex d-sm-none btn volunto-btn-primary mt-9 px-5">Regístrate y pruébalo</a>
          </div>
        </div>
      </section>
    </div>
    <section class="container-fluid section5">
      <div class="row">
        <div class="col col-md-12 col-12">
          <div class="content">
            <span class="subtitle">La seguridad</span>
            <h3 class="font-pt">La <span>seguridad</span> no solo es importante, es el corazón de <span>Volunto</span>.</h3>
            <p>La información que almacenes en <strong>Volunto</strong> está totalmente encriptada y solo tú podrás desencriptarla. <strong>Volunto</strong> está diseñado para proteger tus datos contra vulneraciones de la seguridad, accesos no autorizados y/o robos. </p>
            <img alt="Guarda tu legado digital" src="../assets/img/image_banner5.png" />
          </div>
        </div>
      </div>
    </section>
    <section class="container-fluid ">
      <div class="row">
        <div class="section51">
          <div class="content">
            <div class="content_block">
              <div class="icon"><img src="../assets/img/img_section5_1.png" alt="Tu contraseña y la doble autenticación protege tus datos"></div>
              <span>Tu contraseña y la doble autenticación protege tus datos</span>
              <p>Solo tú conoces la contraseña de tu cuenta; esta nunca se almacenará junto a tus datos ni se enviará por la red. Además para acceder necesitarás un segundo código que será enviado a tu dispositivo seleccionado.</p>
            </div>
          </div>
          <div class="content">
            <div class="content_block">
              <div class="icon"><img src="../assets/img/img_section5_2.png" alt="Cada bloque de tu Legado Digital se encripta por separado"></div>
              <span>Cada bloque de tu Legado Digital se encripta por separado</span>
              <p>Cada Legado es almacenado en silos encriptados de forma individual y almacenados de forma distribuida. Solo a través del Token único y con expiración controlada que se te  envía cada vez que quieres acceder a cualquier parte de tu información es posible ver la información.</p>
            </div>
          </div>
          <div class="content">
            <div class="content_block">
              <div class="icon"><img src="../assets/img/img_section5_3.png" alt="Todos tus datos son almacenados encriptados con AES 256"></div>
              <span>Todos tus datos son almacenados encriptados con AES 256</span>
              <p>Los datos almacenados son encriptados usando el método de encriptación AES 256-bit que es un método de cifrado extremadamente seguro que ha demostrado ser extremadamente resistente a una amplia variedad de ataques.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="container-fluid section6">
      <img class="d-flex d-sm-none" alt="Guarda tu legado digital" src="../assets/img/image_banner6.png" />
      <div class="row">
        <div class="col col-md-12 col-12">
          <div class="content">
            <h3 class="font-pt">Accede de forma <span>gratuita</span> y comienza a crear tu Legado con <span>Volunto.</span> </h3>
            <img class="d-none d-sm-block" alt="Guarda tu legado digital" src="../assets/img/image_banner6.png" />
            <a href="https://app.volun.to/auth/login" class="btn volunto-btn-primary mt-3 px-5">Comienza a crear tu Legado Digital</a>
          </div>
        </div>
      </div>
    </section>
    <section class="container-fluid section7" id="prices">
      <div class="row">
        <div class="col col-md-12 col-12">
          <div class="content">
            <span class="subtitle">Precios</span>
            <h3 class="font-pt">Si con la versión gratuita no es suficiente y necesitas más, contrata la opción <span>Premium</span> y tendrás acceso a todas las funcionalidades</h3>
            <img class="d-block d-sm-none" src="../assets/img/image_banner7.png" alt="Precios">
          </div>
          <!-- <div class="content-button-toggle">
            <div class="button-cover">
              <div class="button r" id="button-1">
                <input id="checkPrice" type="checkbox" class="checkbox" onchange="changePrice()" />
                <div class="knobs"></div>
                <div class="layer"></div>
              </div>
            </div>
          </div>
          <div class="content-prices">
            <span id="price-anual-mobile" class="d-content d-sm-none price-anual">36<sub>€ /año</sub></span>
            <span id="price-anual" class="d-none d-sm-block price-anual">36<sub>€ /año</sub></span>
            <img class="d-none d-sm-block" src="../assets/img/image_banner7.png" alt="Precios">
            <span id="price-monthly-mobile" class="d-content d-sm-none price-monthly">3<sub>,99€ /mes</sub></span>
            <span id="price-monthly" class="d-none d-sm-block price-monthly">3<sub>,99€ /mes</sub></span>
          </div> -->
          <div class="section71">
            <p class="font-pt">
            <span>¡PROMOCIÓN!</span> hasta el 31/12/2023<br />
            Regístrate ahora, totalmente <span>GRATIS</span><br />
            <strong>Si te registras ahora, dispondrás de una cuenta Gratis de por vida</strong>
            </p>
          </div>
          <a href="https://app.volun.to/auth/register" class="btn volunto-btn-primary mt-3 px-5">Registrarse</a>
          <h4 class="font-pt">"La <span>tranquilidad</span> de dejarlo todo bien <span>atado</span>"</h4>
        </div>
      </div>
    </section>
    <section class="container-fluid section8">
      <div class="row">
        <div class="col col-md-12 col-12">
          <div class="content">
            <span class="subtitle">EMBAJADORES</span>
            <h3 class="font-pt">¿Quiéres estar informado de todas las novedades de Volunto?</h3>
            <p>Déjanos tu email y te informaremos de todas las novedades y de cómo  ser uno de nuestros primeros clientes. A cambio recibirás una suscripción vitalicia.</p>
            <div class="col-sm-8 mx-auto">
              <div class="d-block d-sm-flex">
                <div class="d-flex flex-column" style="flex-basis:100%">
                  <input type="text" name="email" placeholder="Dirección de email" v-model="newsletter_email" />
                  <span class="d-block d-sm-none text-white mt-1">Nunca compartiremos este email.</span>
                  <span class="d-none d-sm-block text-start mt-1">Nunca compartiremos este email.</span>
                </div>
                <button class="btn volunto-btn-primary px-4 mt-3 mt-sm-0" @click="submitNewsletter" style="height: 55px;">
                  <span v-if="!newsing">Enviar</span>
                  <div class="spinner-border" style="width: 1.3rem; height: 1.3rem;" role="status" v-else />
                </button>
              </div>
              <div class="alert alert-success disclaimer-contact text-center mt-3" role="alert" v-if="newsOK">
                Hemos añadido tu email a nuestra lista de distribución.
              </div>
              <div class="alert alert-danger disclaimer-contact text-center mt-3" role="alert" v-if="newsError">
                Ha habido un problema. Inténtalo de nuevo.
              </div>
                  </div>
          </div>
        </div>
      </div>
    </section>
    <section class="container-fluid section9 d-block d-sm-none">
      <div class="row">
        <div class="col col-md-12 col-12">
          <div class="content">
            <h3 class="font-pt">Síguenos</h3>
            <p>Si quieres saber sobre nosotros síguenos a través de las redes sociales.</p>
            <ul>
              <li><a href="" title="Facebook"><img src="../assets/img/facebook.png" alt="Facebook" /></a></li>
              <li><a href="" title="Twitter"><img src="../assets/img/twitter.png" alt="Twitter" /></a></li>
              <li><a href="" title="Instagram"><img src="../assets/img/instagram.png" alt="Instagram" /></a></li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <footer class="container-fluid">
      <a href="/" class="navbar-brand mb-10">
        <img src="../assets/img/logo.png" alt="Logo Volunto">
      </a>
      <span>© <strong>Volunto</strong> 2023 all rights reserved</span>
    </footer>
  </div>
  <div class="modal-volunto" v-if="visibleContactForm">
    <div class="modal-volunto-content font-pt">
      <div class="button-close" @click="visibleContactForm=false">X</div>
      <h4>¿Quieres <span>contactar</span> con nosotros? déjanos tus datos y contactaremos contigo.</h4>
      <form @submit.prevent="submitContact">
        <input type="text" v-model="contact_name" placeholder="Nombre" @input="contactOK=false;contactError=false;">
        <input type="text" v-model="contact_email" placeholder="Email" @input="contactOK=false;contactError=false;">
        <textarea type="text" v-model="contact_message" placeholder="Mensaje" @input="contactOK=false;contactError=false;"></textarea>
        <button type="submit" :disabled="contacting">
            <span v-if="!contacting">Enviar</span>
            <div class="spinner-border" style="width: 1.3rem; height: 1.3rem;" role="status" v-else />
        </button>
        <div class="alert alert-success disclaimer-contact text-center" role="alert" v-if="contactOK">
          Mensaje enviado correctamente. En breve te responderemos.
        </div>
        <div class="alert alert-danger disclaimer-contact text-center" role="alert" v-if="contactError">
          Ha habido un problema al enviar el mensaje. Inténtalo de nuevo.
        </div>
      </form>
    </div>
  </div>

</template>

<style>
  @import "../assets/styles/global.css";
  @import "../assets/styles/index.css";
</style>